import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/core.css";
Vue.config.productionTip = false;

import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY, {
  debug: process.env.NODE_ENV === "development",
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
